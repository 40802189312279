.let-mat-dialog {
    &.min-dialog-height {
        min-height: 65vh;
    }

    &.safariMobileHeight {
        min-height: 80vh !important;
        max-height: 82vh;
    }

    @media (max-width: 1024px) {
        height: 90vh !important;
    }

    @media (max-width: 576px) {
        width: 90vw;
        height: 90vh;
        max-width: 300px;

        &.vertical {
            height: 92vh;
        }

        &.auto {
            height: auto;
        }

        section.edit.mat-dialog-content {
            max-height: 100vh;
        }
    }

    &.full {
        @media (max-width: 767px) {
            min-width: 275px;
            height: auto;
        }
    }

    &.flex-footer {
        .actions {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            align-items: flex-end;
            background: white;
            width: 100%;
            box-sizing: border-box;
        }
    }

    @media (max-width: 767px) {
        width: 90vw;
        max-width: 100%;

        section.edit.mat-dialog-content {
            max-height: 100vh !important;
        }
    }
}

.mat-dialog-content {
    @media (max-width: 576px) {
        max-height: 100vh;
    }
}

.dialog-container .mat-dialog-container,
.unconstrained-dialog .mat-dialog-container {
    overflow: hidden !important;
}
