@import 'colors.less';
@import 'dialog.less';

@modal-backdrop-index: 100;
@modal-content-index: 101;
@navi-index: 90;
@navi-backdrop-index: 91;
@navi-sidebar-index: 92;

body {
    margin: 0;
    padding: 0;
    font-family: Verdana, sans-serif;
    font-size: 16px;
    line-height: 20px !important;
}

textarea {
    resize: none;
}

.h4-header {
    margin: 12px 0 8px;
}

.advert-details-section {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    background-color: white;
    margin-bottom: 20px;

    ak-message-box {
        width: 66%;

        @media (max-width: 1199px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            margin-bottom: 24px;
        }
    }

    &.empty {
        display: none;
    }

    .header {
        margin-bottom: 36px;
        display: flex;
        align-items: center;
        position: relative;

        .validation-icon {
            font-size: 24px;

            &.valid {
                color: @green;
            }

            &.invalid {
                color: @error-red;
            }
        }

        button {
            margin-left: 16px;
        }

        .subtitle {
            margin-right: 24px;

            @media (max-width: 767px) {
                margin-right: 8px;
            }
        }

        .loader {
            position: absolute;
            background-color: white;
            height: 30px;
            width: 30px;
            top: 0;
            right: 0;
        }
    }
}

.action-icon {
    cursor: pointer;
}

.disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
}

.trim {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @line-grey;

    .tab-item {
        color: @primary-text-color;
        padding: 10px 8px;
        border-radius: 8px 8px 0 0;
        margin-right: 8px;
        cursor: pointer;
        outline: none;
        transition: 0.2s background-color;

        &:hover {
            color: black;
            background-color: white;
            text-decoration: underline;
        }

        &.selected {
            color: @primary-text-color;
            background-color: @primary-color;
        }
    }
}

.option-modal {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    min-height: 200px;

    @media (max-width: 767px) {
        min-width: auto;
    }

    .head {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .options,
    .finish,
    .loading {
        flex-grow: 1;
    }

    mat-form-field {
        width: 100%;
    }

    .actions {
        display: flex;
        justify-content: flex-end;

        button:last-of-type {
            margin-left: 16px;
        }
    }
}

.stats {
    .stat-group {
        margin-bottom: 16px;
    }

    .teaser-stats-group {
        display: flex;
        margin-bottom: 16px;

        .item {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;
        }
    }

    .teaser-contact {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 100%;
            text-align: center;
        }
    }
}

.modal-base {
    display: flex;
    flex-direction: column;

    .head {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .subtitle {
            margin-right: 24px;
        }
    }

    .actions {
        display: flex;
        align-self: flex-end;

        button:last-child {
            margin-left: 16px;
        }
    }
}

mat-icon.no-margin-right {
    margin-right: 0 !important;
}

mat-icon.no-margin-left-and-right {
    margin-right: -4px !important;
    margin-left: 4px !important;
}

.mat-chip-list .mat-chip-list-wrapper {
    margin-right: 6px;
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

.mdc-button .mat-icon {
    margin-top: 5px;
}

mat-form-field.active > div:first-child {
    background-color: @active-filter-color !important;
}

autocomplete.active > mat-form-field > div:first-child {
    background-color: @active-filter-color!important;
}
.mat-table {
    box-shadow: none;
}
