@import 'colors.less';
@import 'fonts.less';
@import 'common.less';

.form {
    display: flex;
    flex-direction: column;

    .properties {
        display: flex;
        flex-wrap: wrap;

        .property {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            flex: 0 0 32%;
            margin-right: 2%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .value,
            .preview-label {
                display: none;
            }
        }

        .property.single {
            width: 100%;
            margin: 0;
            margin-bottom: 24px;
            flex: none;
        }

        .property.bool {
            align-self: flex-start;
            flex-direction: row;
            display: flex;
            padding: 16px 0;

            @media (max-width: 767px) {
                padding: 0 0 16px;
            }
        }

        .property.hidden {
            visibility: hidden;
        }
    }
}

.form.preview {
    .properties {
        align-items: flex-start;

        .property {
            margin: 0;
            margin-bottom: 24px;
            justify-content: space-between;
            width: 100%;

            .value,
            .preview-label {
                display: inline;
            }

            mat-form-field,
            mat-checkbox {
                display: none;
            }
        }

        .property.bool {
            flex-direction: column;
            align-self: flex-start;
            padding: 0;
        }

        .property.disabled {
            opacity: 1;
        }

        .property.hidden {
            display: none;
        }
    }
}

@media (max-width: 1199px) {
    .form {
        .properties {
            .property {
                flex: 0 0 48%;

                &:nth-child(odd) {
                    margin-right: 4%;
                }

                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
    }

    .form.preview {
        .properties {
            .property {
                width: 100%;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                &:nth-child(even) {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .form {
        .properties {
            .property {
                width: 100%;
                margin-right: 0;
                flex: none;

                &:nth-child(odd) {
                    margin-right: 0;
                }

                &:nth-child(even) {
                    margin-left: 0;
                }
            }

            .property.hidden {
                display: none;
            }
        }
    }

    .form.preview {
        .properties {
            .property {
                max-width: 100%;

                .value {
                    max-width: 50%;
                    .trim;
                }
            }
        }
    }
}
