@import 'colors.less';

.title {
    font-size: 32px;
    line-height: 36px;
    color: @primary-text-color;
}

.subtitle {
    font-size: 24px;
    line-height: 32px;
    color: @primary-text-color;
}

.heading {
    font-size: 20px;
    line-height: 24px;
    color: @primary-text-color;
}

.link,
a {
    color: @primary-text-color;
    cursor: pointer;
    
		&.no-bold {
			font-weight: 100;
		}
}
